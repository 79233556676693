export const API = {
  oauth: '/oauth/token',
  register: '/users/register',
  emailVerify: '/email/verify',
  forgotPassword: '/users/forgot-password',
  resetPassword: '/users/reset-password',
  userInfo: '/users/info',
  userUpdate: '/users/update',
  userDestroy: '/users/destroy',
  profile: '/profile',
  profileInfo: '/profile/info',
  selectProfile: '/profile/select',
  updateProfile: '/profile/update',
  chat: '/chat',
  affinity: '/affinity',
  searchBar: '/search-bar',
  profileCoordinates: '/profile-coordinates',
  profileImage: '/profile/image',
  notifications: '/notifications',
  notificationsSeen: '/notifications/seen',
  friends: '/friends',
  sendFriendRequest: '/friends/send',
  getFriendsNotifications: '/friends/notifications',
  accept: '/accept',
  deny: '/deny',
  clear: '/clear',
  picture: '/picture',
  banner: '/banner',
  unfriend: '/unfriend',
  connectionsVisibility: '/connections-visibility',
  socialLinks: '/socialLinks',
  socialLinksCategories: '/socialLinks/categories',
  frequency: '/frequency',
  liveLocData: '/profile/update/coordinates',
  pingTest: '/test/ping',
  fileTest: '/test/image',
  dataTest: '/test/update-personal-quote',
  liveUpdate: '/update-markers/live',
  staticUpdate: '/update-markers/static',
  blockedUser: '/profile/friends/blocked',
  archive: '/profile/friends/archive',
  guestNotification: '/guest-notifications',
  avatarMarkerBound: '/profile/image-many'
}
